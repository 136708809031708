import {PlayContext} from "../../hooks/play-context";
import {useContext} from "react";
import {Button} from "@material-ui/core";
import CategoryCard from "../CategoryCard";

export default function PlayChooseCategory () {
	const play = useContext(PlayContext)

	const hasActiveCat = !!play.activeCategory.id

	return <div>
		{!play.isActivePlayer && <div>{play.activePlayer.name} is choosing a category</div>}
		{play.isActivePlayer && <>Choose a category:</>}
		<div>
			{!play.categoriesOnDeck.length && <div>No categories on deck.</div>}
			{play.categoriesOnDeck.map((cat) => {
				let reroll = <></>
				if (play.isActivePlayer && !hasActiveCat) {
					reroll = <Button size={"small"} variant={"outlined"} color={"primary"} onClick={() => play.chooseCategory(cat)}>Choose {cat.title}</Button>
				}
				return <CategoryCard active={cat.active} key={cat.id} category={cat} actions={reroll} />
			})}
		</div>
	</div>
}
