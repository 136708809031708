import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import {Category} from '../types/category-types';
import {Player} from '../types/player-types';
import {Bet} from "../types/bet-types";
import {Attempt} from "../types/attempt-types";
import {Question} from "../types/question-types";
import {CardHeader} from "@material-ui/core";
import {ReactNode} from "react";
import Points from "./Points";
import PossibleAnswers from "./PossibleAnswers";

type QuestionResultsProps = {
	bets: Bet[]
	player: Player
	attempt: Attempt
	question: Question
	thisPlayer?: Player
}

export default function QuestionResults({thisPlayer, attempt, question, bets, player}:QuestionResultsProps) {
	let activePlayerName = player.name
	let activePlayerNameStart = player.name
	if (thisPlayer && player.id == thisPlayer.id) {
		activePlayerNameStart = 'You'
		activePlayerName = 'you'
	}
	return (
		<Container className="questionBlock" maxWidth="sm">
			<Card variant="outlined">
				<CardContent>
					<div>{activePlayerNameStart} got {attempt.num_correct} out of {question.num_answers} correct for <strong>{Points(attempt.points_earned)}</strong></div>
					{bets.length > 0 && <ul>
						{bets.map((bet, idx) => {
							let betterName : ReactNode = bet.player_name

							if (thisPlayer) {
								betterName = bet.player_id == thisPlayer.id ? <strong>You</strong> : bet.player_name
							}

							return <li key={idx}>{betterName} bet {bet.choice} {activePlayerName} and {bet.won ? 'won' : 'lost'} {bet.amount} point{bet.amount===1 ? '': 's'}</li>
						})}
					</ul>}
					{!bets.length && <div>Nobody placed any bets :(</div>}
				</CardContent>
			</Card>
			<PossibleAnswers answers={question.answers} />
		</Container>
	);
}



