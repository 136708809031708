import {useParams} from "react-router-dom"
import ManageContextProvider, {ManageContext} from "../hooks/manage-context";
import ManageIntro from "../components/Manage/ManageIntro";
import ManageChooseCategory from "../components/Manage/ManageChooseCategory";
import ManageBettingOpen from "../components/Manage/ManageBettingOpen";
import ManageBettingClosed from "../components/Manage/ManageBettingClosed";
import {useContext} from "react";
import ManagePosingQuestion from "../components/Manage/ManagePosingQuestion";
import ManageRevealingAnswer from "../components/Manage/ManageRevealingAnswer";
import Tabs, {TabContent} from "../components/Tabs";
import ManageLeaderboard from "../components/Manage/ManageLeaderboard";
import ManageSettings from "../components/Manage/ManageSettings";
import ManagePlayers from "../components/Manage/ManagePlayers";

export default function Manage () {
	const params = useParams<{manageCode:string}>()
	const code = params.manageCode


	const tabs : TabContent[] = [
		{
			label: 'Manage',
			children: <ManageSwitch />
		},
		{
			label: 'Leaderboard',
			children: <ManageLeaderboard />
		},
		{
			label: 'Settings',
			children: <ManageSettings />
		},
		{
			label: 'Players',
			children: <ManagePlayers />
		}
	]


	return <ManageContextProvider manageCode={code}>
		<Tabs tabs={tabs} />
	</ManageContextProvider>
}

function ManageSwitch () {
	const manage = useContext(ManageContext)
	const game = manage.game
	document.title = 'Manage ' + game.id
	if (manage.error) {
		return <div>Error: {manage.error}</div>
	}
	return <div className="game-container">
		{!manage.loading && <>
			<h1>
				Hello, Admin.
			</h1>
			<p>Current phase: {game.phase}</p>
			{(game.phase === 'intro' || game.phase === 'leaderboard') && <ManageIntro />}
			{game.phase === 'choosing_category' && <ManageChooseCategory />}
			{game.phase === 'betting_open' && <ManageBettingOpen />}
			{game.phase === 'betting_closed' && <ManageBettingClosed />}
			{game.phase === 'posing_question' && <ManagePosingQuestion />}
			{game.phase === 'revealing_answer' && <ManageRevealingAnswer />}
		</>}
	</div>
}
