import {Category} from "../types/category-types";
import {Button, CardActions} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {ReactNode} from "react";

export type CategoryProps = {
	category: Category,
	actions?: ReactNode
	active: boolean
}
export default function CategoryCard ({active, category, actions}:CategoryProps) {
	return <Card className={"category-card"}  variant={"outlined"} color={active ? 'primary' : 'default'}>
		<CardContent>
			<div className="centered-text"><strong>{category.title}</strong></div>
			<div className="small-text">{category.num_questions - category.num_asked} of {category.num_questions} question{category.num_questions === 1 ? '' : 's'} left</div>
		</CardContent>
		{actions && <CardActions>{actions}</CardActions>}
	</Card>
}
