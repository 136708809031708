import {CardHeader, Container} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

export default function PossibleAnswers ({answers} : {answers: string[]}) {
	return <Container><div className="possible-answers">
		<h4>Correct Answers</h4>
			<ul className={"possible-answers"}>
				{answers.map((answer, idx) => {
					return <li key={idx}>{answer}</li>
				})}
			</ul>
		</div>
		</Container>
}
