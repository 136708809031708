import {ManageContext} from "../../hooks/manage-context";
import {Button} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useContext} from "react";
import CategoryCard from "../CategoryCard";
import api from "../../lib/api";
import {Category} from "../../types/category-types";
import NumAvailableCategories from "../NumAvailableCategories";

export default function ManageChooseCategory () {
	const manage = useContext(ManageContext)
	if (!manage.activePlayer) {
		return <>No player</>
	}

	async function prevState () {
		await manage.showLeaderboard()
	}

	async function rerollCategory (cat: Category) {
		await api.post(`/manage/${manage.manage_code}/reroll-category`, {
			categoryId: cat.id
		})
	}

	const playerName = (manage.activePlayer && manage.activePlayer.name) || '???'

	const numAvailableCategories = manage.numAvailableCategories
	return <div>
		<p>{playerName} is choosing from these categories:</p>
		<div>
			{!manage.categoriesOnDeck.length && <div>No categories on deck.</div>}
			{manage.categoriesOnDeck.map((cat) => {
				let reroll = <>
					<Button size={"small"} variant={"outlined"} color={"primary"} onClick={() => rerollCategory(cat)}>Reroll</Button>
					{/*<Button size={"small"} variant={"outlined"} color={"primary"} onClick={() => alert('Not implemented')}>Choose This For {playerName}</Button>*/}
				</>
				if (numAvailableCategories === manage.categoriesOnDeck.length) {
					reroll = <></>
				}
				return <CategoryCard active={cat.active} key={cat.id} category={cat} actions={reroll} />
			})}
		</div>
		<NumAvailableCategories numAvailable={numAvailableCategories} numOnDeck={manage.categoriesOnDeck.length} />
		<div className={"manage-phase-buttons"}>
			<Button color={"primary"} variant={"contained"} onClick={prevState}><ArrowBackIosIcon /> Back to Leaderboard</Button>
			{/*<Button color={"primary"} variant={"contained"}>Pose Question <ArrowForwardIosIcon /></Button>*/}
		</div>
	</div>
}
