import {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {API_URL} from "../lib/api";

type useGameSocketProps = {
	play?: string
	manage?: string
	setGameState: Function
}

export default function useGameSocket ({play, manage, setGameState} : useGameSocketProps)  {
	const [_, setSocket] = useState<any>(null);

	useEffect(() => {
		const query : any = {}
		if (play) {
			query.play = play
		} else if (manage) {
			query.manage = manage
		} else {
			throw new Error('I dont like not having code')
		}
		const newSocket = io(`${API_URL}`, {
			withCredentials: true,
			query: query
		});
		setSocket(newSocket);

		newSocket.on('gamestate', (data) => {
			if (!data) {
				console.error(`Got falsy data from gamestate emit`, data)
				return
			}

			setGameState(data)
		})

		return () => {
			newSocket.close();
		}
	}, []);
}
