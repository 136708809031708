import {createContext, ReactNode, useEffect, useState} from "react";
import api from "../lib/api";
import {Player} from "../types/player-types";
import {Category} from "../types/category-types";
import {IGameContext, newEmptyGameState, newEmptyPlayer, newGameContextBase, reduceGameState} from "./game-context";
import {GameState} from "../types/game-types";
import {Bet, BetChoice} from "../types/bet-types";
import useGameSocket from "./use-game-socket";

type MCPPProps = {
	children: ReactNode,
	playCode: string
}

interface IPlayContext extends IGameContext {
	thisPlayer: Player
	thisPlayerBet?: Bet
	isActivePlayer: boolean
	hasPlacedBet: boolean
	chooseCategory: Function
	setGameState: Function
	placeBet: Function
	tiedWithPlayers: Player[]
}

export const PlayContext = createContext<IPlayContext>({
	...newGameContextBase(),
	thisPlayer: newEmptyPlayer(),
	isActivePlayer: false,
	hasPlacedBet: false,
	chooseCategory: () => {},
	setGameState: () => {},
	placeBet: () => {},
	tiedWithPlayers: []
})

export default function PlayContextProvider({children, playCode}:MCPPProps) {
	const [error, setError] = useState<any>(null)
	const [gameState, setGameState] = useState<GameState>(newEmptyGameState())
	const [sessionPlayer, setSessionPlayer] = useState<Player>(newEmptyPlayer)
	const [loading, setLoading] = useState<boolean>(true)
	useGameSocket({play: playCode, setGameState})

	async function loadState () {
		setLoading(true)
		try {
			const res = await api.get(`/play/${playCode}`)
			setGameState(res.data.state)
			setSessionPlayer(res.data.player)
			setError(null)
		} catch (ex) {
			console.error(ex)
			setError(ex)
		}
		setLoading(false)
	}

	useEffect(() => {
		loadState()
	}, [])


	async function chooseCategory (cat:Category) {
		try {
			await api.post(`/play/${playCode}/choose-category`, {
				categoryId: cat.id
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function placeBet (choice: BetChoice, points: number) {
		try {
			await api.post(`/play/${playCode}/place-bet`, {
				choice,
				amount: points,
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	const base = reduceGameState(loading, gameState)
	let thisPlayer = newEmptyPlayer()

	if (sessionPlayer && sessionPlayer.id) {
		thisPlayer = base.players.find(p => p.id === sessionPlayer.id) || newEmptyPlayer()
	}
	const thisPlayerBet = base.bets.find(b => b.player_id === thisPlayer.id)
	const hasPlacedBet =  !!thisPlayerBet
	const isActivePlayer = base.players.findIndex(p => p.active && p.id === thisPlayer.id) >= 0

	const tiedWidth = base.players.filter((p) => {
		return p.ranking === thisPlayer.ranking && p.id !== thisPlayer.id
	})

	const value : IPlayContext = {
		...base,
		error,
		hasPlacedBet,
		thisPlayerBet,
		isActivePlayer,
		placeBet: placeBet,
		thisPlayer,
		chooseCategory,
		tiedWithPlayers: tiedWidth,
		setGameState,
	}
	return <PlayContext.Provider value={value}>{children}</PlayContext.Provider>
}
