import {ManageContext} from "../../hooks/manage-context";
import {useContext, useState} from "react";
import ActiveQuestion from "../ActiveQuestion";
import {Button} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import api from "../../lib/api";

export default function ManagePosingQuestion () {
	const manage = useContext(ManageContext)
	const [correctAnswers, setCorrectAnswers] = useState<number[]>([])
	async function prevState () {
		await manage.openBetting()
	}

	async function toggleAnswer (answerIdx: number) {
		const idx = correctAnswers.indexOf(answerIdx)
		if (idx == -1) {
			const copy = [...correctAnswers, answerIdx]
			setCorrectAnswers(copy)
		} else {
			correctAnswers.splice(idx, 1)
			setCorrectAnswers([...correctAnswers])
		}
	}

	function revealAnswers () {
		manage.submitResults(correctAnswers.length)
	}

	const question = manage.activeQuestion

	return <div>
		<ActiveQuestion question={question} category={manage.activeCategory} player={manage.activePlayer} />
		<div>
			<h3>Possible Answers</h3>
			<ul>
				{question.answers.map((a, idx) => {
					return <li key={idx}>
						<label>
							<input type={'checkbox'} checked={!!correctAnswers.includes(idx)} onChange={() => toggleAnswer(idx)} />
							{a}
						</label>
					</li>
				})}
			</ul>
			<p>{correctAnswers.length}/{question.num_answers} right so far</p>
			<Button color={"primary"} variant={"contained"} onClick={revealAnswers}> Submit Result &amp; Reveal Answer</Button>
		</div>
		<div className={"manage-phase-buttons"}>
			<Button color={"primary"} variant={"contained"} onClick={prevState}><ArrowBackIosIcon /> Back to Betting</Button>
		</div>
	</div>
}
