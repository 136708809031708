import {ManageContext} from "../../hooks/manage-context";
import {useContext} from "react";
import Leaderboard from "../Leaderboard";
import {Player} from "../../types/player-types";

export default function ManageIntro () {
	const m = useContext(ManageContext)

	const actions = [
		{
			handler: async (player: Player) => {
				const pts = prompt(`Give ${player.name} points`)
				if (!pts) {
					return
				}
				const ptsInt = parseInt(pts)
				if (isNaN(ptsInt)) {
					return
				}

				const note = prompt('Note')

				await m.givePlayerPoints(player.id, ptsInt, note)
			},
			label: '+pts'
		}
	]

	return <div>
		<Leaderboard players={m.players} actions={actions} />
	</div>
}
