import {ManageContext} from "../../hooks/manage-context";
import {useContext} from "react";
import {Button, Input, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Player} from "../../types/player-types";

export default function ManageSettings () {
	const manage = useContext(ManageContext)

	async function addPlayer () {
		const name = prompt('Player name')
		if (!name) {
			return
		}
		await manage.addPlayer(name)
	}

	async function copy (e: any) {
		await navigator.clipboard.writeText(e.target.value)
	}

	async function deletePlayer (player: Player) {
		if (prompt(`Type DELETE to delete ${player.name}`) !== "DELETE") {
			return
		}

		await manage.deletePlayer(player.id)
	}

	const {players} = manage

	return <div>
		<Button color={"primary"} variant={"contained"} type={"button"} onClick={() => addPlayer()}>Add Player</Button>

		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Invite</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{players.map((player) => {
					return <TableRow>
						<TableCell>
							{player.name}
						</TableCell>
						<TableCell>
							<Input style={{width: '100%'}} value={`https://trivia.colinkierans.com/play/${player.play_code}`} onClick={copy} />
						</TableCell>
						<TableCell>
							<Button color={"secondary"} variant={"outlined"} type={"button"} onClick={() => deletePlayer(player)}>Delete</Button>
						</TableCell>
					</TableRow>
				})}
			</TableBody>
		</Table>

	</div>
}
