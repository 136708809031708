import {ManageContext} from "../../hooks/manage-context";
import {Button} from "@material-ui/core";
import {useContext} from "react";
import PreviousAttempts from "../PreviousAttempts";
import NumAvailableCategories from "../NumAvailableCategories";

export default function ManageIntro () {
	const manage = useContext(ManageContext)
	return <div>
		<h2>Choose Active Player</h2>
		<ul className={"choose-list"}>{manage.players.map((player) => {
			return <li key={player.id} style={{marginBottom: '1em'}}>
				<Button color={player.active ? 'primary' : 'default'} variant="contained" onClick={() => manage.setActivePlayer(player)}>Make {player.name} Active</Button>
			</li>
		} )}</ul>
		<NumAvailableCategories numAvailable={manage.numAvailableCategories} numOnDeck={manage.categoriesOnDeck.length} />
		<h3>Recent Attempts</h3>
		<PreviousAttempts attempts={manage.attempts} />
	</div>
}
