import {Category} from "../types/category-types";
import {Player} from "../types/player-types";
import {Game} from "../types/game-types";
import {Bet} from "../types/bet-types";
import {Question} from "../types/question-types";
import {Attempt} from "../types/attempt-types";

type GameState = {
	game: any
	categories: Category[]
	players: Player[]
	bets: Bet[]
	activeQuestion?: Question
	activeAttempt?: Attempt
	attempts: Attempt[]
}

export interface IGameContext {
	activeCategory: Category
	activePlayer: Player
	activeQuestion: Question
	activeAttempt: Attempt
	attempts: Attempt[]
	gameState: GameState
	game: any
	players: Player[]
	categories: Category[]
	categoriesOnDeck: Category[]
	loading: boolean
	error: any
	manage_code: string
	play_code: string
	bets: Bet[]
	numAvailableCategories: number,
}

export function newEmptyCategory () : Category {
	return {
		id: '',
		title: '',
		num_questions: 0,
		num_asked: 0,
		active: false,
		on_deck: false
	}
}

export function newEmptyPlayer () : Player {
	return {
		id: '',
		active: false,
		play_code: '',
		points: 0,
		name: '',
		ranking: ''
	}
}

export function newEmptyBet() : Bet {
	return {
		id: '',
		player_name: '',
		amount: 0,
		choice: 'for',
		attempt_id: '',
		won: null,
		player_id: ''
	}
}

export function newEmptyAttempt() : Attempt {
	return {
		id: '',
		num_correct: 0,
		points_earned: 0,
		player_id: '',
		question_id: ''
	}
}

export function newEmptyQuestion() : Question {
	return {
		id: '',
		num_answers: 0,
		bet_answers_threshold: 0,
		prompt: '',
		answers: []
	}
}

export function newGameContextBase () : IGameContext {
	return {
		activeCategory: newEmptyCategory(),
		activePlayer: newEmptyPlayer(),
		activeQuestion: newEmptyQuestion(),
		activeAttempt: newEmptyAttempt(),
		attempts: [],
		manage_code: '',
		play_code: '',
		gameState: {
			game: newEmptyGame(),
			categories: [],
			players: [],
			bets: [],
			attempts: []
		},
		error: null,
		game: {},
		loading: true,
		players: [],
		categories: [],
		categoriesOnDeck: [],
		bets: [],
		numAvailableCategories: 0
	}
}

export function newEmptyGame () : Game {
	return {
		id: '',
		phase: "leaderboard",
		title: '',
		manage_code: '',
		points_per_question: 0,
		points_per_round: 0,
		min_bet: 0,
		max_bet: 0,
	}
}

export function newEmptyGameState () : GameState {
	return {
		game: newEmptyGame(),
		players: [],
		categories: [],
		bets: [],
		attempts: []
	}
}

export function reduceGameState (loading: boolean, gameState: GameState) {
	if (!gameState) {
		throw new Error(`Got a bad game state to reduce`)
	}
	let activePlayer:Player|undefined
	if (!loading) {
		activePlayer = gameState.players.find((player:Player) => {
			return player.id === gameState.game.active_player_id
		})
	}
	let activeCategory:Category|undefined
	if (!loading) {
		activeCategory = gameState.categories.find((category:Category) => {
			return category.id === gameState.game.active_category_id
		})
	}
	let activeAttempt:Attempt|undefined
	if (!loading) {
		activeAttempt = gameState.activeAttempt
	}

	if (!activeCategory) {
		activeCategory = newEmptyCategory()
	}
	if (!activeAttempt) {
		activeAttempt = newEmptyAttempt()
	}
	if (!activePlayer) {
		activePlayer = newEmptyPlayer()
	}

	const activeQuestion = gameState.activeQuestion || newEmptyQuestion()
	const categoriesOnDeck = gameState.categories.filter(x => x.on_deck)
	const numAvailableCategories = gameState.categories.filter(x => x.num_asked < x.num_questions).length

	return {
		activeCategory,
		activePlayer,
		activeQuestion,
		activeAttempt,
		attempts: gameState.attempts,
		manage_code: gameState.game.manage_code,
		play_code: gameState.game.play_code,
		gameState,
		game: gameState.game,
		loading,
		bets: gameState.bets,
		players: gameState.players,
		categories: gameState.categories,
		categoriesOnDeck,
		numAvailableCategories
	}
}
