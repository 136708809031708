import {ManageContext} from "../../hooks/manage-context";
import {FormEvent, useContext, useState} from "react";
import {Button, FormControl, FormHelperText, Input, InputLabel} from "@material-ui/core";

export default function ManageSettings () {
	const manage = useContext(ManageContext)
	const [pointsPerQuestion, setPointsPerQuestion] = useState<number>(manage.game.points_per_question)
	const [numCategoriesOnDeck, setNumCategoriesOnDeck] = useState<number>(manage.game.num_categories_on_deck)
	const [pointsPerRound, setPointsPerRound] = useState<number>(manage.game.points_per_round)
	const [minBet, setMinBet] = useState<number>(manage.game.min_bet)
	const [maxBet, setMaxBet] = useState<number>(manage.game.max_bet)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	async function submit (e: FormEvent) {
		e.preventDefault()
		if (isSubmitting) {
			return
		}
		setIsSubmitting(true)
		try {
			await manage.saveSettings({
				points_per_question: pointsPerQuestion,
				points_per_round: pointsPerRound,
				num_categories_on_deck: numCategoriesOnDeck,
				min_bet: minBet,
				max_bet: maxBet,
			})
			alert('Saved')
		} catch (ex) {
			alert(ex.toString())
		} finally {
			setIsSubmitting(false)
		}
	}

	async function resetGame () {
		if (prompt('Type RESET') !== 'RESET') {
			return
		}

		await manage.resetGame()
	}

	return <div>
		<form onSubmit={submit}>
			<FormControl>
				<InputLabel>Categories On Deck</InputLabel>
				<Input value={numCategoriesOnDeck} onChange={(e: any) => setNumCategoriesOnDeck(e.target.value)} />
				<FormHelperText>How many random categories the active player will choose from.</FormHelperText>
			</FormControl>
			<FormControl>
				<InputLabel>Points Per Question</InputLabel>
				<Input value={pointsPerQuestion} onChange={(e: any) => setPointsPerQuestion(e.target.value)} />
				<FormHelperText>If you get all answers you get this many points.</FormHelperText>
			</FormControl>
			<FormControl>
				<InputLabel>Points Per Round</InputLabel>
				<Input value={pointsPerRound} onChange={(e: any) => setPointsPerRound(e.target.value)} />
				<FormHelperText>Everyone gets this many points when a new player is chosen.</FormHelperText>
			</FormControl>
			<FormControl>
				<InputLabel>Minimum Bet</InputLabel>
				<Input value={minBet} onChange={(e: any) => setMinBet(e.target.value)} />
			</FormControl>
			<FormControl>
				<InputLabel>Maximum Bet</InputLabel>
				<Input value={maxBet} onChange={(e: any) => setMaxBet(e.target.value)} />
			</FormControl>
			<FormControl>
				<Button type={"submit"} variant={"contained"} color={"primary"} style={{marginRight: '0.5em'}}>Save Settings</Button>
				<Button type={"button"} onClick={resetGame} variant={"outlined"} color={"primary"}>Reset Game</Button>
			</FormControl>
		</form>
	</div>
}
