import '../styles/App.css';
import { useHistory } from 'react-router-dom'

function Header() {
  const history = useHistory()

  let text = "Disco Trivialis"

  return (

      <div className="Header">
        <header id="header" className="Header">
          <a className="headerTitle">{text}</a>
        </header>
      </div>
    );
  }


export default Header;
