import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8900"

const api = axios.create({baseURL:API_URL})

api.interceptors.response.use((response) => {
    return response
}, (err) => {
    if (err.response && err.response.data) {
        if (typeof err.response.data === 'string') {
            return Promise.reject(err.response.data)
        }
        return Promise.reject(JSON.stringify(err.response.data))
    }
    return Promise.reject(err.toString())
})

export default api
