import {createContext, ReactNode, useEffect, useState} from "react";
import api from "../lib/api";
import {Player} from "../types/player-types";
import {IGameContext, newEmptyGameState, newGameContextBase, reduceGameState} from "./game-context";
import {GameState} from "../types/game-types";
import useGameSocket from "./use-game-socket";

type MCPPProps = {
	children: ReactNode,
	manageCode: string
}

interface IManageContext extends IGameContext {
	setActivePlayer: Function
	setGameState: Function
	showLeaderboard: Function
	closeBetting: Function
	poseQuestion: Function
	submitResults: Function
	openBetting: Function
	saveSettings: Function
	givePlayerPoints: Function
	resetGame: Function
	addPlayer: Function
	deletePlayer: Function
}

export const ManageContext = createContext<IManageContext>({
	...newGameContextBase(),

	setActivePlayer: () => {},
	setGameState: () => {},
	showLeaderboard: () => {},
	poseQuestion: () => {},
	submitResults: () => {},
	closeBetting: () => {},
	openBetting: () => {},
	saveSettings: () => {},
	resetGame: () => {},
	givePlayerPoints: () => {},
	addPlayer: () => {},
	deletePlayer: () => {},
})

export default function ManageContextProvider({children, manageCode}:MCPPProps) {
	const [error, setError] = useState<any>(null)
	const [gameState, setGameState] = useState<GameState>(newEmptyGameState())
	const [loading, setLoading] = useState<boolean>(true)
	useGameSocket({manage: manageCode, setGameState})

	async function loadState () {
		setLoading(true)
		try {
			const res = await api.get(`/manage/${manageCode}`)
			setGameState(res.data)
			setError(null)
		} catch (ex) {
			setError(ex)
		}
		setLoading(false)
	}

	useEffect(() => {
		loadState()
	}, [])

	async function setActivePlayer (player:Player) {
		try {
			await api.post(`/manage/${manageCode}/choose-player`, {
				playerId: player.id
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function showLeaderboard () {
		try {
			await api.post(`/manage/${manageCode}/show-leaderboard`)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function poseQuestion () {
		try {
			await api.post(`/manage/${manageCode}/pose-question`)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function closeBetting () {
		try {
			await api.post(`/manage/${manageCode}/close-betting`)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function openBetting () {
		try {
			await api.post(`/manage/${manageCode}/open-betting`)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function resetGame () {
		await api.post(`/manage/${manageCode}/reset-game`)
	}

	async function submitResults (numCorrect: number) {
		try {
			await api.post(`/manage/${manageCode}/submit-result`, {
				numCorrect
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}
	type GameSettings = {
		points_per_question: number
		points_per_round: number
		min_bet: number
		max_bet: number
	}
	async function saveSettings (newSettings: GameSettings) {
		try {
			await api.post(`/manage/${manageCode}/save-settings`, newSettings)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}
	async function givePlayerPoints (pid: string, points: number, note: string) {
		try {
			await api.post(`/manage/${manageCode}/give-player-points`, {
				playerId: pid,
				points,
				note
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function addPlayer (name: string) {
		try {
			await api.post(`/manage/${manageCode}/add-player`, {
				name
			})
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	async function deletePlayer (pid: string) {
		try {
			await api.delete(`/manage/${manageCode}/player/${pid}`)
		} catch (ex) {
			console.error(ex)
			alert(ex.toString())
		}
	}

	const base = reduceGameState(loading, gameState)
	const value : IManageContext = {
		...base,
		error,
		givePlayerPoints,
		submitResults,
		setActivePlayer,
		setGameState,
		openBetting,
		showLeaderboard,
		poseQuestion,
		addPlayer,
		deletePlayer,
		closeBetting,
		resetGame,
		saveSettings
	}
	return <ManageContext.Provider value={value}>{children}</ManageContext.Provider>
}
