import {ManageContext} from "../../hooks/manage-context";
import {Button} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useContext, useState} from "react";
import ManageBetsTable from "./ManageBetsTable";
import ActiveQuestion from "../ActiveQuestion";
import PossibleAnswers from "../PossibleAnswers";
import Container from "@material-ui/core/Container";

export default function ManageChooseCategory () {
	const [showQuestion, setShowQuestion] = useState(false)

	const manage = useContext(ManageContext)
	if (!manage.activePlayer) {
		return <>No player</>
	}

	async function prevState () {
		await manage.showLeaderboard()
	}

	async function closeBetting () {
		await manage.closeBetting()
	}

	function toggleShowQuestion() {
		setShowQuestion(!showQuestion)
	}

	const bets = manage.bets
	const {activeQuestion, activePlayer} = manage
	const numAnswers = activeQuestion.num_answers
	return <div>
		<div>
			<strong>{activePlayer.name}</strong> is answering a question with <strong>{activeQuestion.num_answers} answer{activeQuestion.num_answers === 1 ? '' : 's'}</strong> from <strong>{manage.activeCategory.title}</strong>
		</div>
		<div style={{padding: '10px 0px'}}>
			<Button variant={"outlined"} onClick={toggleShowQuestion}>{showQuestion ? 'Hide Preview' : 'Preview Question & Answers'}</Button>
			{showQuestion && <Container className="" maxWidth="sm">
				<ActiveQuestion question={activeQuestion} category={manage.activeCategory} player={manage.activePlayer} />
				<PossibleAnswers answers={activeQuestion.answers} />
			</Container>}
		</div>
		<div>The other players must bet if {manage.activePlayer.name} will get <strong>{numAnswers == 1 ? 'it right' : `at least ${manage.activeQuestion.bet_answers_threshold} answers`}</strong></div>
		<h3>Bets</h3>
		<ManageBetsTable bets={bets} />
		<div className={"manage-phase-buttons"}>
			<Button color={"primary"} variant={"contained"} onClick={prevState}><ArrowBackIosIcon /> Back to Leaderboard</Button>
			<Button color={"primary"} variant={"contained"} onClick={closeBetting}>Show Question <ArrowForwardIosIcon /></Button>
		</div>
	</div>
}
