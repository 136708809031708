import {useParams} from "react-router-dom"
import PlayContextProvider, {PlayContext} from "../hooks/play-context";
import {useContext} from "react";
import PlayIntro from "../components/Play/PlayIntro";
import PlayChooseCategory from "../components/Play/PlayChooseCategory";
import PlayPosingQuestion from "../components/Play/PlayPosingQuestion";
import PlayBettingOpen from "../components/Play/PlayBettingOpen";
import PlayRevealingAnswer from "../components/Play/PlayRevealingAnswer";
import PlayLeaderboard from "../components/Play/PlayLeaderboard";
import Tabs, {TabContent} from "../components/Tabs";
import Points from "../components/Points";
import {commaAndJoin} from "../lib/helpers";

export default function Play () {
	const params = useParams<{playCode:string}>()
	const code = params.playCode

	const tabs : TabContent[] = [
		{
			label: 'Play',
			children: <PlaySwitch />
		},
		{
			label: 'Leaderboard',
			children: <div>
				<h1>Leaderboard</h1>
				<PlayLeaderboard />
			</div>
		}
	]

	return <PlayContextProvider playCode={code}>
		<Tabs tabs={tabs} />
	</PlayContextProvider>
}

function PlaySwitch () {
	const play = useContext(PlayContext)
	if (play.error) {
		return <div>Error: {play.error}</div>
	}
	const {thisPlayer, game} = play
	document.title = thisPlayer.name

	let ranking = thisPlayer.ranking

	if (play.tiedWithPlayers.length) {
		const tiedWithNames = play.tiedWithPlayers.map(x => x.name)
		ranking = 'tied with ' + commaAndJoin(tiedWithNames) + ' for ' + thisPlayer.ranking
	}

	function getRandomInt(max:number) {
		return Math.floor(Math.random() * max)
	}

	const styles = {
		root: {
		  padding: "10px"
		}
	  };

	const greetings = ["Get ready", "You've got  it", "Keep it together"]

	return <div className="game-container">
		{!play.loading && <>
			<div className="welcome-phrase">
				{greetings[getRandomInt(3)]} {thisPlayer.name}.
			</div>
			<h4 className="small-text">You are {ranking} with {Points(thisPlayer.points)}</h4>
			{(game.phase === 'intro' || game.phase === 'leaderboard') && <PlayLeaderboard />}
			{game.phase === 'choosing_category' && <PlayChooseCategory />}
			{game.phase === 'betting_open' && <PlayBettingOpen />}
			{game.phase === 'betting_closed' && <div>Betting is closed</div>}
			{game.phase === 'posing_question' && <PlayPosingQuestion />}
			{game.phase === 'revealing_answer' && <PlayRevealingAnswer />}
		</>}
	</div>
}
