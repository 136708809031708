import {PlayContext} from "../../hooks/play-context";
import {useContext} from "react";
import Leaderboard from "../Leaderboard";
import PreviousAttempts from "../PreviousAttempts";

export default function PlayIntro () {
	const play = useContext(PlayContext)
	const thisPlayer = play.thisPlayer
	return <div>
		<Leaderboard players={play.players} highlight={thisPlayer.id} />
		<h3>Recent Attempts</h3>
		<PreviousAttempts attempts={play.attempts} />
	</div>
}
