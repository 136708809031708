import {ManageContext} from "../../hooks/manage-context";
import {Button} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useContext} from "react";
import ManageBetsTable from "./ManageBetsTable";

export default function ManageChooseCategory () {
	const manage = useContext(ManageContext)

	async function prevState () {
		alert('You cant do that')
	}

	async function poseQuestion () {
		await manage.poseQuestion()
	}

	const bets = manage.bets
	const numAnswers = manage.activeQuestion.num_answers
	return <div>
		<p>Betting is closed.</p>
		<h3>Bets</h3>
		<ManageBetsTable bets={bets} />
		<div className={"manage-phase-buttons"}>
			<Button color={"primary"} variant={"contained"} onClick={prevState}><ArrowBackIosIcon /> Open Betting Again</Button>
			<Button color={"primary"} variant={"contained"} onClick={poseQuestion}>Pose Question <ArrowForwardIosIcon /></Button>
		</div>
	</div>
}
