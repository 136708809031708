import {Bet} from "../../types/bet-types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {TableBody, TableCell, TableRow} from "@material-ui/core";

export default function ManageBetsTable ({bets} : {bets: Bet[]}) {
	return <TableContainer>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Better</TableCell>
					<TableCell>Choice</TableCell>
					<TableCell>Bet Amount</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{bets.length === 0 && <TableRow>
					<TableCell colSpan={3}>No bets just yet</TableCell>
				</TableRow>}
				{bets.map((bet, i) => {
					return <TableRow key={i}>
						<TableCell>{bet.player_name}</TableCell>
						<TableCell>{bet.choice}</TableCell>
						<TableCell>{bet.amount}</TableCell>
					</TableRow>
				})}
			</TableBody>
		</Table>
	</TableContainer>
}
