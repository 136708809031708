import {useParams} from "react-router-dom"
import '../styles/App.css';

export default function Game(){

    const params = useParams<{gamecode:string, phase:string}>()
    const code = params.gamecode
    const gamePhase = params.phase

    return <div className="game-container">
        {gamePhase === 'waiting' && <>Waiting for the next player to be chosen</>}
        </div>
}
