import {ManageContext} from "../../hooks/manage-context";
import {useContext, useState} from "react";
import ActiveQuestion from "../ActiveQuestion";
import {Button} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import QuestionResults from "../QuestionResults";

export default function ManagePosingQuestion () {
	const manage = useContext(ManageContext)
	async function prevState () {
		await manage.poseQuestion()
	}

	function showLeaderboard () {
		manage.showLeaderboard()
	}

	const question = manage.activeQuestion

	return <div>
		<ActiveQuestion question={question} category={manage.activeCategory} player={manage.activePlayer} />
		<QuestionResults bets={manage.bets} player={manage.activePlayer} attempt={manage.activeAttempt} question={manage.activeQuestion} />
		<div className={"manage-phase-buttons"}>
			<Button color={"primary"} variant={"contained"} onClick={prevState}><ArrowBackIosIcon /> Pose Question</Button>
			<Button color={"primary"} variant={"contained"} onClick={showLeaderboard}>Back to Leaderboard <ArrowForwardIosIcon /></Button>
		</div>
	</div>
}
