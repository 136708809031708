import {PlayContext} from "../../hooks/play-context";
import {useContext} from "react";
import ActiveQuestion from "../ActiveQuestion";

export default function PlayChooseCategory () {
	const play = useContext(PlayContext)
	return <div>
		<ActiveQuestion question={play.activeQuestion} category={play.activeCategory} player={play.activePlayer} />
	</div>
}
