import React, {ReactNode} from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CoreTabs from '@material-ui/core/Tabs';
import CoreTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: "white",
	},
	stem: {
		backgroundColor: "black",
	}
}));

export type TabContent = {
	label: string
	children: ReactNode
}

type TabsProps = {
	tabs: TabContent[]
}

export default function Tabs ({tabs} : TabsProps) {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<CoreTabs value={value} onChange={handleChange} className={classes.stem} centered>
					{tabs.map((tab, idx) => {
						return <CoreTab key={idx} label={tab.label}{...a11yProps(idx)} />
					})}
				</CoreTabs>
			</AppBar>
			{tabs.map((tab, idx) => {
				return <TabPanel key={idx} value={value} index={idx}>{tab.children}</TabPanel>
			})}
		</div>
	);
}
