import Points from "./Points";
import {Attempt} from "../types/attempt-types";

type PreviousAttemptsProps = {
	attempts: Attempt[]
}

export default function PreviousAttempts({attempts} : PreviousAttemptsProps) {
	return <div className={"previous-attempts-container"}>
	{attempts.length === 0 && <div>No attempts yet.</div>}
	{attempts.length > 0 && <ol className={"previous-attempts"}>
		{attempts.map((a) => {
			return <li>
				{a.player_name} got {a.num_correct} correct for {Points(a.points_earned)}
				<br /><small>{a.prompt}</small>
			</li>
		})}
	</ol>}
	</div>
}
