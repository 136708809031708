import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Question } from '../types/question-types';
import { Category } from '../types/category-types';
import { Player } from '../types/player-types';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 20,
	},
	question: {
		fontSize: 35,
	},
	pos: {
		marginBottom: 12,
	},
});

type ActiveQuestionProps = {
	question:Question
	category:Category
	player:Player
}

export default function ActiveQuestion(props:ActiveQuestionProps) {
	const classes = useStyles();
	const question = props.question.prompt
	const category = props.category.title
	const playername = props.player.name

	return (
		<Card className="category-card" variant="outlined">
			<CardContent>
				<Typography className={classes.title} color="textSecondary" gutterBottom>
					Question for {playername}
				</Typography>
				<Typography className={classes.question} variant="h5" component="h2">
					{question}
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					Category: {category}
				</Typography>
			</CardContent>
		</Card>
	);
}



