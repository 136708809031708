import {
	Button,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@material-ui/core";
import {Player} from "../types/player-types";

type LeaderboarAction = {
	handler: (player: Player) => void
	label: string
}

type LeaderBoardProps = {
	players:any[]
	highlight?: string
	actions?: LeaderboarAction[]
}

const useStyles = makeStyles({
	table: {
		maxWidth: 650,
	},
});

function Leaderboard({players, highlight, actions = []}:LeaderBoardProps) {
	const classes = useStyles();

	return <TableContainer component={Paper}>
		<Table className={classes.table} size="small">
			<TableHead>
				<TableRow>
					<TableCell width={10}>#</TableCell>
					<TableCell>Name</TableCell>
					<TableCell>Points</TableCell>
					{!!actions.length && <TableCell></TableCell>}
				</TableRow>
			</TableHead>
			<TableBody>
				{players.map((player) => {
					const styles : React.CSSProperties = {
						fontWeight: highlight === player.id ? 'bold' : undefined,
						backgroundColor: highlight === player.id ? '#F1F1F1' : undefined
					}
					return <TableRow key={player.id}>
						<TableCell component="th" scope="row" style={styles}>
							{player.ranking}
						</TableCell>
						<TableCell scope="row" style={styles}>
							{player.name}
						</TableCell>
						<TableCell style={styles}>
							{player.points.toLocaleString()}
						</TableCell>
						{!!actions.length && <TableCell>
							{actions.map((action) => {
								return <Button onClick={() => action.handler(player)}>{action.label}</Button>
							})}
						</TableCell>}
					</TableRow>
				})}
			</TableBody>
		</Table>
	</TableContainer>

}


export default Leaderboard;
