import './styles/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Landing from "./pages/Landing"
import Game from "./pages/Game"
import '@fontsource/roboto';
import Play from './pages/Play';
import Manage from './pages/Manage';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/game/:gamecode/:phase">
            <Game />
          </Route>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/play/:playCode">
            <Play  />
          </Route>
          <Route path="/manage/:manageCode">
            <Manage  />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
