import {PlayContext} from "../../hooks/play-context";
import {useContext} from "react";
import ActiveQuestion from "../ActiveQuestion";
import QuestionResults from "../QuestionResults";

export default function PlayChooseCategory () {
	const play = useContext(PlayContext)
	return <div>
		<ActiveQuestion question={play.activeQuestion} category={play.activeCategory} player={play.activePlayer} />
		<QuestionResults bets={play.bets} thisPlayer={play.thisPlayer} player={play.activePlayer} attempt={play.activeAttempt} question={play.activeQuestion} />
	</div>
}
