import {PlayContext} from "../../hooks/play-context";
import {useContext, useState} from "react";
import {Button, Slider} from "@material-ui/core";
import {BetChoice} from "../../types/bet-types";
import ToggleButton from '@material-ui/lab/ToggleButton';
import {ToggleButtonGroup} from "@material-ui/lab";
import Points from "../Points";

export default function PlayChooseCategory () {
	const [choice, setChoice] = useState<BetChoice | null>(null);
	const [amount, setAmount] = useState<number>(1)
	const [betError, setBetError] = useState('')
	const play = useContext(PlayContext)
	const question = play.activeQuestion
	let betPrompt

	const defaultBet = Math.round((play.game.min_bet + play.game.max_bet) / 2)

	if (question.num_answers === 1) {
		betPrompt = <>Will {play.activePlayer.name} get it right?</>
	} else {
		betPrompt = <>Will {play.activePlayer.name} get at least <strong>{question.bet_answers_threshold}</strong> answer{question.bet_answers_threshold === 1 ? '' : 's'}?</>
	}

	const handleChoice = (event: React.MouseEvent<HTMLElement>, newAlignment: BetChoice | null) => {
		setChoice(newAlignment);
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			setAmount(newValue);
		}
	};

	async function placeBet () {
		setBetError('')
		if (amount <= 0) {
			setBetError('Amount must be positive')
			return
		}
		if (amount > play.thisPlayer.points) {
			setBetError(`Can't bet more than ${play.thisPlayer.points} point${play.thisPlayer.points == 1 ? '': 's'}`)
			return
		}
		if (choice === null) {
			setBetError('Must choose a bet choice')
			return
		}
		play.placeBet(choice, amount)
	}

	const thisBet = play.thisPlayerBet
	if (thisBet) {
		return <div className="centered-text">You bet {Points(thisBet.amount)} {thisBet.choice} {play.activePlayer.name}.</div>
	}

	if (play.isActivePlayer) {
		return <div className="centered-text">Sit tight while others bet</div>
	}

	const betMarks = [
		{
			value: play.game.min_bet,
			label: play.game.min_bet,
		},
		{
			value: play.game.max_bet,
			label: play.game.max_bet,
		},
	];

	return <div>
		<p className="small-text">{play.activePlayer.name} has chosen the category <strong>{play.activeCategory.title}</strong>.</p>
		<p className="small-text">Their question has <strong>{question.num_answers} answer{question.num_answers === 1 ? '' : 's'}</strong></p>
		{betError && <div ><strong>ERROR:</strong> {betError}</div>}
		<h2 className="centered-text">{betPrompt}</h2>
		<div className="small-text">
			<ToggleButtonGroup
			value={choice}
			exclusive
			onChange={handleChoice}
			aria-label="text alignment"
		>
			<ToggleButton style={{width: '30vw', color: 'green'}} value="on" aria-label="left aligned">
				Yes
			</ToggleButton>
			<ToggleButton style={{width: '30vw',color: 'red'}} value="against" aria-label="centered">
				No
			</ToggleButton>
		</ToggleButtonGroup>
		</div>
		<h2 className="centered-text">Bet Points</h2>
		<br />
		<div style={{maxWidth: '400px'}}>
			<Slider style={{color: '#880a0a'}} onChange={handleSliderChange} marks={betMarks} valueLabelDisplay="on" min={play.game.min_bet} max={play.game.max_bet} defaultValue={defaultBet} aria-labelledby="bet" />
		</div>
		<br />
		<Button variant={"outlined"} color={"primary"} onClick={placeBet}>Place Bet</Button>
	</div>
}
